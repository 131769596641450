<template>
  <div>
    <div class="integrationInfo" v-loading="detailsLoad">
      <h2>{{integrationDetails.name}}</h2>
      <h5><svg-icon icon-class="certified" />限企业实名用户使用</h5>
      <p>{{integrationDetails.description}}</p>
      <div class="authNumber">
        <el-select v-model="authNumber" placeholder="请选择" size="small">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        <el-input-number v-model="customNumber" controls-position="right" class="customNumber" v-if="authNumber===-1" :min="1" :max="500000" :step="1" :step-strictly="true"
          size="small"></el-input-number>
        次
      </div>
      <h3 v-if="integrationDetails.price>0"><i>¥ </i>{{totalPrice.toFixed(2)}}</h3>
      <el-button type="primary" @click="applyAccess">立即接入</el-button>
      <div class="connectNow" :class="{animation:animation}">
        <p><el-checkbox v-model="checked"></el-checkbox>同意<a @click="protocolShow='networkIdentity'">《网络身份认证服务协议》</a></p>
      </div>
    </div>
    <div class="commonMainArea integrationRelated">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="产品介绍" name="productIntroduction">
          <div class="productIntroduction">
            <h2>产品详情</h2>
            <p>{{integrationDetails.introduce}}</p>
          </div>
        </el-tab-pane>
        <el-tab-pane label="API文档" name="apiDocument">
          <div class="apiDescribe">
            <p><span>调用地址：</span>{{apiDocument.requestAddress}}</p>
            <p><span>请求方式：</span>{{apiDocument.requestMethod}}</p>
            <p class="hasBorder"><span>支持格式：</span>{{apiDocument.dataFormat}}</p>
            <h2>请求参数：</h2>
            <el-table ref="tableList1" :data="apiDocument.requestParam" style="width: 100%;">
              <el-table-column prop="name" label="名称" width="200"></el-table-column>
              <el-table-column prop="type" label="类型" width="200"></el-table-column>
              <el-table-column prop="isMust" label="是否必须" width="200"></el-table-column>
              <el-table-column prop="example" label="示例值" show-overflow-tooltip></el-table-column>
              <el-table-column prop="description" label="说明" width="300" show-overflow-tooltip></el-table-column>
            </el-table>
            <h2 class="hasMargin">正确返回：</h2>
            <pre>{{apiDocument.trueReturn}}</pre>
            <h2 class="hasMargin">错误返回：</h2>
            <pre>{{apiDocument.errorReturn}}</pre>
            <h2 class="hasMargin">返回字段说明：</h2>
            <el-table ref="tableList1" :data="apiDocument.feildReturn" style="width: 100%;">
              <el-table-column prop="field" label="字段名" width="300"></el-table-column>
              <el-table-column prop="description" label="说明" min-width="100" show-overflow-tooltip></el-table-column>
            </el-table>
            <h2 class="hasMargin">code错误码说明：</h2>
            <el-table ref="tableList1" :data="apiDocument.codeReturn" style="width: 100%;">
              <el-table-column prop="code" label="code" width="300"></el-table-column>
              <el-table-column prop="description" label="说明" min-width="100" show-overflow-tooltip></el-table-column>
            </el-table>
          </div>
        </el-tab-pane>
        <el-tab-pane label="示例代码" name="sampleCode">
          <div class="sampleCode">
            <el-button plain size="small">Java</el-button>
            <pre>{{integrationDetails.exampleCode}}</pre>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <ProtocolDialog v-model="protocolShow" />
  </div>
</template>

<script>
import ProtocolDialog from '@/views/protocol/dialog.vue'
export default {
  data () {
    return {
      detailsLoad: false,
      authNumber: '',
      checked: false,
      options: [],
      activeName: 'productIntroduction',
      integrationDetails: '',
      animation: false,
      customNumber: '',
      apiDocument: {},
      protocolShow: false,
      type: '',
    }
  },
  components: { ProtocolDialog },
  computed: {
    totalPrice () {
      if (this.authNumber <= 0) {
        return this.customNumber * this.integrationDetails.price
      } else {
        return this.authNumber * this.integrationDetails.price
      }
    }
  },
  mounted () {
    this.type = this.$route.query.type
    this.getDetails()
    this.getOrderNumber()
  },
  methods: {
    getDetails () {
      this.detailsLoad = true
      let data = {
        id: this.$parent.integrationId,
        type: this.type,
      }
      this.$api.getByIdDetails(data).then(res => {
        this.detailsLoad = false
        this.integrationDetails = res.data
        this.apiDocument = res.data.apiDocumentVO || {}
      })
    },
    getOrderNumber () {
      this.$api.getTimesList().then(res => {
        let options = []
        for (let i = 0; i < res.data.length; i++) {
          options.push({
            label: res.data[i],
            value: res.data[i]
          })
        }
        this.options = options.concat([{
          label: '自定义',
          value: -1
        }])
      })
    },
    handleClick () {
    },
    verify () {
      this.$bus.$off('afterLogin')
      this.$bus.$on('afterLogin', () => { })
      this.$api.getAccountInfo().then(res => {
        if (res.data.verified) {
          this.enterpriseStatus(res.data.bindCustomer)
        } else {
          this.$confirm('此业务需要【管理员认证】和【企业认证】成功之后才可申请。', '提示', {
            confirmButtonText: '去认证',
            cancelButtonText: '暂不认证',
            type: 'warning'
          }).then(() => {
            this.$router.push({ name: 'account' })
          }).catch(() => {

          });
        }
      })
    },
    enterpriseStatus (bindCustomer) {
      if (bindCustomer) {
        let selectNumber, selectProduct
        this.authNumber <= 0 ? selectNumber = this.customNumber : selectNumber = this.authNumber
        selectProduct = {
          name: this.integrationDetails.name,
          id: this.$parent.integrationId,
          number: selectNumber,
          price: this.totalPrice
        }
        this.$parent.productInfo = selectProduct
        this.$parent.attestationSteps = 2
      } else {
        this.$confirm('此业务需要【企业认证】成功之后才可申请。', '提示', {
          confirmButtonText: '去认证',
          cancelButtonText: '暂不认证',
          type: 'warning'
        }).then(() => {
          this.$router.push({ name: 'enterprise' })
        }).catch(() => { });
      }
    },
    applyAccess () {
      if (this.authNumber <= 0 && this.customNumber <= 0) {
        this.$message.error('请选择次数');
        return false
      }
      if (this.checked) {
        this.verify()
      } else {
        this.hintAnimation()
      }
    },
    hintAnimation () {
      this.animation = true
      setTimeout(() => {
        this.animation = false
      }, 500)
    }
  }
}
</script>

<style lang="scss" scoped>
.integrationInfo {
  padding: 40px 43px 35px;
  background: #ffffff;
  h2 {
    font-size: 18px;
    color: #303b50;
    line-height: 24px;
  }
  h5 {
    font-size: 12px;
    color: #1c67f3;
    line-height: 20px;
    margin: 12px 0 12px;
    font-weight: normal;
    vertical-align: -0.25em;
  }
  .svg-icon {
    width: 16px;
    height: 16px;
    margin-right: 4px;
  }
  & > p {
    font-size: 14px;
    color: #303b50;
    line-height: 19px;
  }
  h3 {
    color: #e5434c;
    font-size: 24px;
    line-height: 19px;
  }
  i {
    font-size: 14px;
  }
  .el-button {
    padding: 11px 20px;
    margin: 32px 0 24px;
  }
}
.authNumber {
  padding: 32px 0;
  .el-select {
    width: 100px;
  }
  ::v-deep .el-select .el-input .el-select__caret {
    color: #303b50;
  }
  .customNumber {
    width: 120px;
    margin-left: 12px;
  }
  ::v-deep .el-input__inner {
    padding-left: 8px;
  }
}
.connectNow {
  font-size: 14px;
  color: #333333;
  line-height: 19px;
  letter-spacing: 1px;
  .el-checkbox {
    margin-right: 4px;
  }
}
.integrationRelated {
  margin-top: 18px;
  padding: 12px 42px;
  background: #fff;
  ::v-deep .el-tabs__header {
    margin: 0;
  }
  pre {
    margin-top: 40px;
  }
}
.productIntroduction {
  h2 {
    font-size: 18px;
    font-weight: bold;
    color: #303b50;
    line-height: 20px;
    margin: 40px 0 14px;
  }
  p {
    font-size: 14px;
    color: #303b50;
    line-height: 28px;
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
@keyframes shake {
  0%,
  100% {
    transform: translate(0);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translate(-8px);
  }
  20%,
  40%,
  60%,
  80% {
    transform: translate(8px);
  }
}
.animation {
  animation: shake 0.5s;
}
.apiDescribe {
  padding: 24px 0;
  p {
    font-size: 14px;
    color: #303b50;
    line-height: 19px;
    padding-bottom: 24px;
  }
  .hasBorder {
    border-bottom: 1px solid #dee2e8;
  }
  h2 {
    font-weight: normal;
    font-size: 14px;
    position: relative;
    padding: 24px 0 24px 12px;
    line-height: 19px;
    color: #303b50;
    &::before {
      content: '';
      width: 2px;
      height: 14px;
      position: absolute;
      top: 26px;
      left: 0;
      background: $primaryColor;
    }
  }
  .hasMargin {
    margin-top: 16px;
  }
  ::v-deep .el-table tr > th {
    background: #f7faff;
    padding: 9px 0;
    color: #6c737b;
    font-weight: normal;
    border-color: #dee0e9;
  }
  ::v-deep .el-table td {
    padding: 13px 0;
    border-color: #dee0e9;
  }
  pre {
    padding: 16px;
    margin: 0;
    background: #f2f3f9;
  }
}
.sampleCode {
  padding-top: 32px;
  ::v-deep .el-button {
    line-height: 12px;
    border-radius: 1px;
    border: 1px solid #1c67f3;
    span {
      color: #1c67f3;
    }
  }
  pre {
    padding: 16px;
    margin: 24px 0;
    background: #f2f3f9;
  }
}
</style>