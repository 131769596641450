<template>
  <div class="interfaceIntegration" v-loading="integrationLoad">
    <el-button plain>API接口集成</el-button>
    <div class="interfaceList clearfix">
      <div class="interfaceItems" v-for="(item,index) of integrationList" :key="index">
        <h2>{{item.name}}</h2>
        <p>{{item.description}}</p>
        <i>企业专享</i>
        <div class="interfaceJump">
          <button @click="applyAccess(item.id)">查看详情</button>
          <button @click="applyAccess(item.id)">申请接入</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      integrationLoad: false,
      integrationList: []
    }
  },
  mounted () {
    this.getList()
  },
  methods: {
    getList () {
      this.integrationLoad = true
      this.$api.getIndentityList().then(res => {
        this.integrationLoad = false
        this.integrationList = res.data
      })
    },
    applyAccess (id) {
      this.$parent.integrationId = id
      this.$parent.attestationSteps = 1
      this.$store.commit('user/setOrderNo', '')
    },
    verify () {
      this.$bus.$off('afterLogin')
      this.$bus.$on('afterLogin', () => { })
      this.$api.getAccountInfo().then(res => {
        if (res.data.verified) {
          this.enterpriseStatus(res.data.bindCustomer)
        } else {
          this.$confirm('此业务需要【管理员认证】和【企业认证】成功之后才可申请。', '提示', {
            confirmButtonText: '去认证',
            cancelButtonText: '暂不认证',
            type: 'warning'
          }).then(() => {
            this.$router.push({ name: 'account' })
          }).catch(() => {

          });
        }
      })
    },
    enterpriseStatus (bindCustomer) {
      if (bindCustomer) {
        this.$parent.attestationSteps = 1
        this.$store.commit('user/setOrderNo', '')
      } else {
        this.$confirm('此业务需要【企业认证】成功之后才可申请。', '提示', {
          confirmButtonText: '去认证',
          cancelButtonText: '暂不认证',
          type: 'warning'
        }).then(() => {
          this.$router.push({ name: 'enterprise' })
        }).catch(() => { });
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.interfaceIntegration {
  padding: 60px 137px 66px;
  background: #ffffff;
  ::v-deep .el-button {
    padding: 9px 20px;
    background: rgba(247, 250, 255, 0);
    border: 1px solid #1c67f3;
    span {
      color: #1c67f3;
    }
  }
}
.interfaceList {
  padding-top: 32px;
}
.interfaceItems {
  width: 302px;
  height: 220px;
  background: #ffffff;
  box-shadow: 4px 4px 16px 0px rgba(29, 38, 51, 0.16);
  border-radius: 2px;
  border: 1px solid #dee2e8;
  padding-top: 42px;
  position: relative;
  margin-right: 60px;
  margin-bottom: 56px;
  float: left;
  &:nth-child(3n) {
    margin: 0;
  }
  i {
    display: inline-block;
    width: 72px;
    height: 26px;
    border: 1px solid #1c67f3;
    font-size: 12px;
    color: #1c67f3;
    line-height: 26px;
    text-align: center;
    position: absolute;
    top: 12px;
    right: 12px;
  }
  h2 {
    font-size: 18px;
    color: #303b50;
    line-height: 24px;
    margin-bottom: 16px;
    padding: 0 24px;
  }
  p {
    font-size: 14px;
    color: #6c737b;
    line-height: 20px;
    padding: 0 24px;
  }
}
.interfaceJump {
  border-top: 1px solid #dee2e8;
  display: flex;
  position: absolute;
  bottom: 0;
  button {
    width: 150px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    font-size: 14px;
    border: none;
    cursor: pointer;
    &:first-child {
      background: #ffffff;
      border-radius: 0px 0px 0px 2px;
      color: #1c67f3;
    }
    &:last-child {
      background: #1c67f3;
      border-radius: 0px 0px 2px 0px;
      color: #ffffff;
    }
  }
}
</style>