<template>
  <div class="networkIdentity">
    <v-head :txt="'网络身份认证服务'" />
    <div class="networkBox">
      <div class="cloudCrumbs">
        <router-link :to="{name:'businessCooperation'}">
          业务合作
        </router-link>
        <span>></span>
        <el-button type="text" :class="{crumbsActive:attestationSteps===0}" @click="changeSteps">网络身份认证服务</el-button>
        <span v-if="attestationSteps>=1">></span>
        <el-button type="text" class="crumbsActive" v-if="attestationSteps>=1">购买</el-button>
      </div>
      <v-interfaceList v-if="attestationSteps===0" />
      <v-purchase v-if="attestationSteps===1" />
      <v-selectEnterprise v-if="attestationSteps===2" />
      <v-onlinePayment v-if="attestationSteps===3" :detailsInfo="detailsInfo" />
    </div>
  </div>
</template>

<script>
import purchase from './purchase.vue';
import interfaceList from './interfaceList.vue';
import onlinePayment from './onlinePayment.vue';
import selectEnterprise from './selectEnterprise.vue';
import head from './../cloudCertificate/components/head.vue';
export default {
  data () {
    return {
      attestationSteps: 0,
      integrationId: '',
      productInfo: '',
      orderNo: '',
      detailsInfo: {},
    }
  },
  components: {
    'v-head': head,
    'v-purchase': purchase,
    'v-interfaceList': interfaceList,
    'v-selectEnterprise': selectEnterprise,
    'v-onlinePayment': onlinePayment
  },
  mounted () {
    this.integrationId = this.$route.query.productId || ''
    if (this.$store.state.user.orderNo !== '') {
      this.getDetailsInfo()
    }
    if (this.$store.state.user.appId !== '') {
      this.attestationSteps = 1
    }
  },
  methods: {
    changeSteps () {
      this.attestationSteps = 0
      this.$store.commit('user/setOrderNo', '')
      this.$store.commit('user/seAppId', '')
      this.$router.push({ name: 'identityAuth', query: {} })
    },
    getDetailsInfo () {
      let data = {
        orderNo: this.$store.state.user.orderNo
      }
      this.$api.getNetworkDetail(data).then(res => {
        this.detailsInfo = res.data
        if (res.data.status === 2) {
          this.attestationSteps = 3
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.networkIdentity {
  min-height: calc(100vh - 60px);
  background: #f5f8fb;
}
.networkBox {
  width: 1300px;
  margin: 60px auto 0;
}
</style>