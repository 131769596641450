<template>
  <div>
    <el-dialog title="企业实名" :visible.sync="realNameVisible" width="608px" :before-close="realNameClose" class="realNameDialog">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="152px">
        <el-form-item label="组织类型" prop="organizationType">
          <el-radio-group v-model="ruleForm.organizationType">
            <el-radio v-for="item in organizationTypeList" :label="item.value" :key="item.value">{{ item.label }}
              <el-tooltip class="item" effect="dark" :content="item.title" placement="bottom">
                <i class="el-icon-warning"></i>
              </el-tooltip>
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <div v-if="ruleForm.organizationType===1">
          <el-form-item label="企业名称" prop="enterpriseName">
            <el-input v-model="ruleForm.enterpriseName" size="medium" maxlength="30" placeholder="请输入企业名称"></el-input>
            <p>请务必与营业执照注册号上的名称保持一致</p>
          </el-form-item>
          <el-form-item label="统一社会信用代码" prop="enterpriseIdentNo">
            <el-input v-model="ruleForm.enterpriseIdentNo" size="medium" maxlength="18" placeholder="请输入统一社会信用代码"></el-input>
            <p>请务必与营业执照上的注册号保持一致</p>
          </el-form-item>
        </div>
        <div v-if="ruleForm.organizationType===2">
          <el-form-item label="个体工商户名称" prop="enterpriseName">
            <el-input v-model="ruleForm.enterpriseName" size="medium" maxlength="30" placeholder="请输入个体工商户名称"></el-input>
            <p>请务必与营业执照上的名称保持一致</p>
          </el-form-item>
          <el-form-item label="统一社会信用代码 或营业执照注册号" prop="enterpriseIdentNo" class="IDNumber">
            <el-input v-model="ruleForm.enterpriseIdentNo" size="medium" maxlength="18" placeholder="请输入统一社会信用代码或营业执照注册号"></el-input>
            <p>请务必与营业执照上的注册号保持一致</p>
          </el-form-item>
        </div>
        <div v-if="ruleForm.organizationType===3">
          <el-form-item label="组织名称" prop="enterpriseName">
            <el-input v-model="ruleForm.enterpriseName" size="medium" maxlength="30" placeholder="请输入组织名称"></el-input>
            <p>请务必与组织机构代码上的名称保持一致</p>
          </el-form-item>
          <el-form-item label="组织机构代码" prop="enterpriseIdentNo">
            <el-input v-model="ruleForm.enterpriseIdentNo" size="medium" maxlength="30" placeholder="请输入组织机构代码"></el-input>
            <p>请务必与组织机构代码上的注册号保持一致</p>
          </el-form-item>
        </div>
      </el-form>
      <div slot="footer" class="realName-footer">
        <el-button @click="realNameClose" size="mini">取 消</el-button>
        <el-button type="primary" @click="continuingCertification" size="mini" :loading="isRealName">确 定</el-button>
      </div>
    </el-dialog>
    <div class="warningBox" v-if="warningVisible">
      <el-alert :title="warningMsg" @close="warningClose" type="warning" show-icon class="warningMsg"></el-alert>
    </div>
  </div>
</template>

<script>
import request from '@/utils/request'
export default {
  data () {
    return {
      realNameVisible: false,
      ruleForm: {
        organizationType: 1,
        enterpriseName: '',
        enterpriseIdentNo: '',
      },
      rules: {
        organizationType: [
          { required: true, message: '请选择组织类型', trigger: 'change' }
        ],
        enterpriseName: [
          { required: true, message: '请输入名称', trigger: 'blur' }
        ],
        enterpriseIdentNo: [
          { required: true, message: '请输入机构代码', trigger: 'change' },
          {
            validator: this.validLicenseNo,
            trigger: 'blur'
          }
        ],
      },
      warningVisible: false,
      warningMsg: '',
      organizationTypeList: [
        {
          value: 1,
          label: '企业',
          title:
            '企业指领取营业执照的有限责任公司、股份有限公司、非公司企业法人、国有企业、个人独资企业及其分支机构、来华从事经营活动和外国(地区)企业及其他经营单位'
        },
        {
          value: 2,
          label: '个体工商户',
          title:
            '个体工商户是指在法律允许的范围内，依法经核准登记，从事工商经营活动的自然人或者家庭'
        },
        {
          value: 3,
          label: '其他组织',
          title:
            '其他组织指在中华人民共和国境内依法注册、依法登记的机关、事业单位、社会团体、学校和民办非企业单位和其他机构'
        }
      ],
      isRealName: false
    }
  },
  props: ['authCustomerId', 'isPersonAuth'],
  methods: {
    validLicenseNo (rule, value, callback) {
      if (
        this.ruleForm.organizationType === 1 &&
        !/^91[A-Za-z0-9]{16}$/.test(value)
      ) {
        callback(new Error('请输入91开头的18位统一社会信用代码'))
      } else if (
        this.ruleForm.organizationType === 2 &&
        !/^92[A-Za-z0-9]{16}$/.test(value)
      ) {
        callback(new Error('请输入92开头的18位统一社会信用代码'))
      } else {
        callback()
      }
    },
    continuingCertification () {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.isRealName = true
          this.$api.getAuthUrl({
            "authCustomerId": this.authCustomerId,
            "isPersonAuth": this.isPersonAuth,
            "haveAgentAuth": false,
            "myAccountInfo": true,
            'isPortalAuth': true,
            enterpriseIdentNo: this.ruleForm.enterpriseIdentNo,
            enterpriseName: this.ruleForm.enterpriseName,
            organizationType: this.ruleForm.organizationType.toString()
          }).then((res) => {
            if (res.code === '0') {
              request({
                url: res.data.longUrl,
                method: 'get',
                params: {},
              }).then(res => {
                window.location.href = res.data.redirectUrl
              })
            } else {
              this.realNameClose()
              this.warningVisible = true
              this.warningMsg = res.msg
            }
          })
        }
      })
    },
    realNameClose () {
      this.realNameVisible = false
      this.isRealName = false
      this.$refs['ruleForm'].resetFields()
    },
    warningClose () {
      this.warningVisible = false
    }
  }
}
</script>

<style lang="scss" scoped>
.realNameDialog {
  ::v-deep .el-dialog__header {
    padding: 0;
    margin: 0 24px;
    border-bottom: 1px solid #dee2e8;
    height: 42px;
    line-height: 42px;
    font-size: 14px;
    font-weight: bold;
    color: #303b50;
  }
  ::v-deep .el-dialog__headerbtn {
    top: 12px;
  }
  ::v-deep .el-dialog__body {
    padding-bottom: 0;
  }
  .el-form-item {
    &:last-child {
      margin-bottom: 10px;
    }
  }
  ::v-deep .el-form-item__label {
    padding-right: 28px;
  }
  ::v-deep .el-form-item__error {
    top: unset;
    bottom: 0;
  }
  .el-radio__label i {
    font-size: 14px;
    color: #d5dbe2;
    margin-left: 8px;
  }
  .el-input {
    width: 408px;
  }
  p {
    font-size: 14px;
    color: #8e959d;
    line-height: 19px;
    letter-spacing: 1px;
    padding-bottom: 14px;
  }
}
.realName-footer {
  text-align: center;
}
.IDNumber {
  ::v-deep .el-form-item__label {
    line-height: 20px;
  }
}
</style>