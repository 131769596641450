<template>
  <div class="cloudHead clearfix">
    <router-link :to="{name:'home'}" class="logo">
      <svg-icon icon-class="zxlogo" />
    </router-link>
    <h3>{{txt}}</h3>
  </div>
</template>

<script>
export default {
  props: ['txt']
}
</script>

<style lang="scss" scoped>
.cloudHead {
  width: 100%;
  height: 60px;
  padding: 0 48px;
  background: #ffffff;
  box-shadow: 0px 4px 8px 0px rgba(31, 37, 81, 0.11);
  position: fixed;
  top: 0;
  z-index: 9;
  h3 {
    width: 1300px;
    margin: 0 auto;
    font-size: 16px;
    color: #303b50;
    line-height: 60px;
    font-weight: normal;
    text-align: center;
  }
  .svg-icon {
    vertical-align: -0.08em;
  }
}
.logo {
  margin-right: 20px;
  color: #1c67f3;
  font-size: 44px;
  line-height: 60px;
  cursor: pointer;
  float: left;
}
</style>