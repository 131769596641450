<template>
  <div class="integratedEnterprise" v-loading="isLoad">
    <div class="productInfo">
      <div class="productHead">
        <span>产品名称</span>
        <span>产品类型</span>
        <span>规格</span>
        <span>价格</span>
      </div>
      <div class="productDetails">
        <p>网络身份认证服务</p>
        <p>{{$parent.productInfo.name}}</p>
        <p>规格：API接口服务<br><i>周期：{{$parent.productInfo.number}}次</i></p>
        <p>{{$parent.productInfo.price}}</p>
      </div>
    </div>
    <div class="productInfo">
      <h2>集成企业</h2>
      <el-table :data="customerList" style="width: 100%" @row-click="getRowInfo">
        <el-table-column prop="select" label="选择" width="80">
          <template slot-scope="scope">
            <el-radio v-model="selectId" :label="scope.row.appId"></el-radio>
          </template>
        </el-table-column>
        <el-table-column prop="appId" label="API认证账号（AppId）"></el-table-column>
        <el-table-column prop="name" label="企业名称"></el-table-column>
        <el-table-column prop="identNo" label="统一社会信用代码"></el-table-column>
        <el-table-column prop="legalPersonName" label="法人姓名"></el-table-column>
      </el-table>
      <p class="newEnterprise" v-if="Number(buyType)!==2"><el-button type="text" @click="completeInfo(false)">使用新企业</el-button></p>
    </div>
    <div class="submitApplication">
      <el-button type="primary" @click="submitOrder">提交申请</el-button>
    </div>

    <entryInfo ref="entryInfo" :isPersonAuth="isPersonAuth" />
  </div>
</template>

<script>
import entryInfo from '@/views/userInfo/enterprise/entryInfo.vue';
export default {
  data () {
    return {
      products: [],
      enterpriseInfo: '',
      customerList: [],
      isLoad: false,
      selectId: '',
      buyType: 1,
      isPersonAuth: '',
    }
  },
  components: {
    entryInfo
  },
  mounted () {
    this.buyType = this.$route.query.buyType || 1
    this.getEnterprise()
  },
  methods: {
    completeInfo (item) {
      this.isPersonAuth = item
      this.$refs.entryInfo.realNameVisible = true
    },
    getRowInfo (row) {
      this.enterpriseInfo = row;
    },
    getEnterprise () {
      this.isLoad = true
      this.$api.getCustomerByCondition({ authStatus: 1, queryKey: this.$store.state.user.appId }).then(res => {
        this.isLoad = false
        this.customerList = res.data.customerList
        if (res.data.customerList.length > 0) {
          this.selectId = res.data.customerList[0].appId
          this.enterpriseInfo = res.data.customerList[0]
        }
      }).catch(() => {
        this.isLoad = false
      })
    },
    submitOrder () {
      if (this.enterpriseInfo.appId === undefined) {
        this.$confirm('该企业未创建AppId，请解绑企业重新认证', '提示', {
          confirmButtonText: '去解绑',
          cancelButtonText: '暂不解绑',
          type: 'warning'
        }).then(() => {
          this.$router.push({ name: 'enterprise' })
        }).catch(() => { })
        return false
      }
      if (this.enterpriseInfo === '') {
        this.$message.error('请选择集成企业');
        return false
      }
      this.isLoad = true
      let data = {
        times: this.$parent.productInfo.number,
        appId: this.enterpriseInfo.appId,
        productId: this.$parent.productInfo.id,
        productType: 0,
        type: this.buyType
      }
      this.$api.setOrderSubmit(data).then(res => {
        this.isLoad = false
        this.$store.commit('user/setOrderNo', res.msg)
        this.$parent.attestationSteps = 3
        this.$parent.getDetailsInfo()
      }).catch(() => {
        this.isLoad = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.integratedEnterprise {
  padding: 40px 24px 283px;
  background: #fff;
}
.productInfo {
  margin-bottom: 22px;
  h2 {
    font-size: 14px;
    font-weight: 700;
    color: #303b50;
    line-height: 19px;
    position: relative;
    border-bottom: 1px solid #dee2e8;
    padding: 16px 12px;
    margin-bottom: 24px;
    &::before {
      position: absolute;
      width: 3px;
      height: 16px;
      content: '';
      background: #1c67f3;
      top: 16px;
      left: 0;
    }
  }
  ::v-deep .el-table th {
    padding: 9px 0;
    background: #f2f3f9;
  }
  ::v-deep .el-table .cell {
    font-weight: normal;
  }
  ::v-deep .el-radio__label {
    display: none;
  }
}
.productHead {
  width: 100%;
  height: 42px;
  background: #f2f3f9;
  padding-left: 40px;
  span {
    display: inline-block;
    width: 280px;
    font-size: 14px;
    color: #6d7882;
    line-height: 42px;
  }
}
.productDetails {
  padding: 16px 40px;
  border-bottom: 1px solid #dee0e9;
  p {
    display: inline-block;
    width: 280px;
    font-size: 14px;
    color: #303b50;
    line-height: 19px;
    vertical-align: text-top;
    padding-right: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  i {
    display: inline-block;
    margin-top: 14px;
  }
}
.newEnterprise {
  padding: 4px 0;
  border-bottom: 1px solid #dee0e9;
  text-align: center;
}
.submitApplication {
  padding: 36px;
  text-align: center;
  .el-button {
    width: 150px;
    padding: 11px 20px;
  }
}
</style>