<template>
  <div class="networkIdentity">
    <div class="networkBox">
      <div class="onlinePaymentBox" v-loading="detailsLoad">
        <div class="paymenInfo clearfix">
          <h2>订单信息</h2>
          <div class="orderInfo">
            <p><span>订单号：</span>{{detailsInfo.orderNo}}</p>
            <p><span>订单状态：</span>{{detailsInfo.status|orderStatus}}</p>
            <p><span>订单类型：</span>{{detailsInfo.type|orderType}}</p>
            <p><span>订单创建时间：</span>{{detailsInfo.createTime}}</p>
          </div>
        </div>
        <div class="paymenInfo">
          <h2>产品信息</h2>
          <div class="productHead">
            <span>产品名称</span>
            <span>产品类型</span>
            <span>规格</span>
            <span>价格</span>
          </div>
          <div class="productDetails">
            <p>{{detailsInfo.productType|productType}}</p>
            <p>{{detailsInfo.productName}}</p>
            <p>规格：{{detailsInfo.productSpecs|productSpecs}}<br><i>周期：{{detailsInfo.times}}次</i></p>
            <p>{{detailsInfo.price}}</p>
          </div>
        </div>
        <div class="paymenInfo">
          <h2>集成企业</h2>
          <div class="productHead">
            <span>企业名称</span>
            <span>API认证账号（AppId）</span>
            <span>统一社会信用代码</span>
            <span>法人姓名</span>
          </div>
          <div class="productDetails">
            <p>{{detailsInfo.customerName}}</p>
            <p>{{detailsInfo.appId}}</p>
            <p>{{detailsInfo.identNo}}</p>
            <p>{{detailsInfo.legalPersonName}}</p>
          </div>
        </div>
        <div class="paymenDetails">
          <p><span>总计</span><i>¥</i><em>{{detailsInfo.price}}</em>（支持在线开票）</p>
          <h3><img src="@/assets/icons/service/wx-peyment-icon.png">微信支付</h3>
          <dl>
            <dt>
              <div class="onPayment" v-if="onPayment">
                <i class="el-icon-success icon"></i>
                <div>付款中，请稍后…</div>
              </div>
              <img :src="qrCodeSrc">
            </dt>
            <dd @click="generatePayQrcode(true)">重新生成二维码</dd>
          </dl>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { orderStatus, orderType, productSpecs, productType } from '@/utils/typeMatch';
export default {
  data () {
    return {
      detailsLoad: false,
      codeSrc: '',
      onPayment: false,
      timeout: '',
      qrCodeSrc: ''
    }
  },
  props: ['detailsInfo'],
  filters: {
    orderType,
    productType,
    orderStatus,
    productSpecs
  },
  mounted () {
    this.generatePayQrcode(true)
  },
  methods: {
    generatePayQrcode (reset) {
      this.detailsLoad = true
      clearTimeout(this.timeout)
      this.$api.getPayQrcode({ orderNo: this.$store.state.user.orderNo, reset: reset }).then(res => {
        this.detailsLoad = false
        if (res.code === '0') {
          this.qrCodeSrc = 'data:image/jpg;base64,' + res.data.body
          this.getIsPay()
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    getIsPay () {
      let self = this
      this.$api.getOrderIsPay({
        orderNo: this.$store.state.user.orderNo
      }).then(res => {
        let code = res.code
        if (code === '0') {
          this.$router.push({ name: 'integration' })
        } else if (code === '1') {
          this.timeout = setTimeout(() => {
            self.getIsPay()
          }, 3000)
        } else if (code === '-1' || code === '2') {
          this.errorTime = setTimeout(() => {
            this.generatePayQrcode(true)
          }, 3000);
        } else if (code === '-2') {
          this.onPayment = true
        } else {
          this.$message.error(res.msg)
        }
      })
    }
  },
  beforeDestroy () {
    clearTimeout(this.timeout)
    this.timeout = ''
    clearTimeout(this.errorTime)
    this.errorTime = ''

  }
}
</script>

<style lang="scss" scoped>
.networkIdentity {
  min-height: calc(100vh - 60px);
  background: #f5f8fb;
}
.networkBox {
  width: 1300px;
}
.onlinePaymentBox {
  padding: 13px 40px;
  background: #fff;
}
.paymenInfo {
  margin-bottom: 22px;
  h2 {
    font-size: 14px;
    font-weight: 700;
    color: #303b50;
    line-height: 19px;
    position: relative;
    padding: 16px 12px 19px;
    &::before {
      position: absolute;
      width: 3px;
      height: 16px;
      content: '';
      background: #1c67f3;
      top: 16px;
      left: 0;
    }
  }
  ::v-deep .el-table th {
    padding: 9px 0;
    background: #f2f3f9;
  }
  ::v-deep .el-table .cell {
    font-weight: normal;
  }
  ::v-deep .el-radio__label {
    display: none;
  }
}
.orderInfo {
  p {
    float: left;
    font-size: 14px;
    color: #303b50;
    line-height: 19px;
    &:nth-child(1) {
      width: 37%;
      margin-bottom: 23px;
    }
    &:nth-child(2) {
      width: 63%;
      margin-bottom: 23px;
    }
    &:nth-last-child(2) {
      width: 37%;
    }
    &:last-child {
      width: 63%;
    }
  }
  span {
    color: #6c737b;
  }
}
.productHead {
  width: 100%;
  height: 42px;
  background: #f2f3f9;
  padding-left: 24px;
  span {
    display: inline-block;
    width: 280px;
    font-size: 14px;
    color: #6d7882;
    line-height: 42px;
  }
}
.productDetails {
  padding: 16px 24px;
  border-bottom: 1px solid #dee0e9;
  p {
    display: inline-block;
    width: 280px;
    font-size: 14px;
    color: #303b50;
    line-height: 19px;
    vertical-align: text-top;
    padding-right: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  i {
    display: inline-block;
    margin-top: 14px;
  }
}
.paymenDetails {
  padding-top: 28px;
  p {
    font-size: 14px;
    color: #303b50;
    line-height: 21px;
  }
  span {
    font-weight: bold;
    margin-right: 140px;
  }
  i {
    font-size: 16px;
    color: #fbb900;
  }
  em {
    color: #fbb900;
    font-size: 24px;
  }
  h3 {
    width: 114px;
    height: 38px;
    background: #ffffff;
    border-radius: 2px;
    border: 1px solid #1c67f3;
    font-weight: normal;
    font-size: 14px;
    color: #303b50;
    line-height: 38px;
    padding: 0 10px;
    margin-top: 40px;
    background: url('~@/assets/image/personCenter/selected.png') 98px 23px
      no-repeat;
    background-size: 14px;
    cursor: pointer;
    img {
      width: 24px;
      margin-right: 7px;
    }
  }
  dl {
    margin-top: 24px;
    padding-bottom: 63px;
  }
  dt {
    position: relative;
    width: max-content;
    img {
      width: 128px;
      height: 125px;
    }
  }
  .onPayment {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(#000, 0.63);
    z-index: 99;
    padding-top: 32px;
    font-size: 12px;
    color: #fff;
    box-sizing: border-box;
    text-align: center;
    .icon {
      // color: $primaryColor;
      color: #52c41a;
      font-size: 32px;
      margin-bottom: 12px;
    }
  }
  dd {
    margin-top: 16px;
    font-size: 14px;
    color: #1c67f3;
    line-height: 19px;
    padding-left: 15px;
    cursor: pointer;
  }
}
</style>